import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`How We Can Make These Ideas Happen`}</h1>
    <p>{`One big question I often get asked when I tell people about my list of ideas is: `}<strong parentName="p">{`how are you going to get any of these approved?`}</strong>{` And I always applaud them for asking that questions because it's the next step in thinking. I can have as many ideas as I want, but none of them will mean anything unless they can get approved!`}</p>
    <p>{`In both Youth Action Team and Irvine Lights, I've learned that ideas are hard to convey verbally. There are multiple ideas going on in everyone's head and it will not be easy to pass it on. The leadership structure in YAT is often someone will mention an idea to me, I will pass it onto other staff in my team, then we'll present it to our bosses. In between every time someone verbally describes the idea, the concept itself changes. Whether it be a specific detail or a certain aspect, it's inevitable that things will change between every communication of the idea. What we do in both YAT and Irvine Lights, to avoid this, is creating proposals. `}</p>
    <p>{`Proposals are short documents that discuss every part of an event/activity. They go into detail about how it will run and address any frequently asked questions or crucial talking points. Since everything is written down, everyone will have the same mental picture and the concept doesn't change. Throughout all of my leadership experiences, I've found this to be the most effective solution, as it will speed up a lot of meeting time and headaches.`}</p>
    <p>{`One example of a proposal I created this year, along with my council, was the Prom proposal. It was a 10 page document which went over how a socially distanced Prom would work. The actual approval process is on hold due to local and state guidelines, but we thoroughly outlined all our ideas into that document, so there wouldn't need to be as much of a back and forth.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      